import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 分类
export function fetchList(data) {
  return request({
    url: '/seller/gcate/list',
    method: 'post',
    data: param2Obj(data)
  })
}

export function saveCategory(data) {
  return request({
    url: '/seller/gcate/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function categoryList(data) {
  return request({
    url: '/seller/gcate/listChilds',
    method: 'post',
    data: param2Obj(data)
  })
}

export function categoryShop(data) {
  return request({
    url: '/seller/gcate/tree',
    method: 'post',
    data: param2Obj(data)
  })
}

export function categoryHistory(data) {
  return request({
    url: '/seller/gcate/historyCate',
    method: 'post',
    data: param2Obj(data)
  })
}

export function searchCategory(data) {
  return request({
    url: '/seller/gcate/search',
    method: 'post',
    data: param2Obj(data)
  })
}