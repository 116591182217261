<template>
	<el-scrollbar class="uu-box">
		<div class="uu-publish">
			<div class="publish-breadcrumb" v-if="isEdit">
				<span class="title">{{$t('seller.goods.text1')+':'}}</span>
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item v-for="(item,index) in selectCate" :key="index"><span>{{item.cate_name}}</span></el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="publish-steps" v-else>
				<div class="item" v-for="(item,index) in stepsOption" :key="index"
					:class="{active: publishSteps - 1 == index}">{{item}}</div>
			</div>
			<el-form ref="goodsForm1" :model="goodsForm" :rules="goodsRules" label-width="160px" size="small"
				v-if="publishSteps === 1">
				<div class="publish-section">
					<el-form-item :label="$t('seller.formItem.goodsImage')" prop="goods_images" ref="goods_images_item">
						<div class="section-tip">{{$t('seller.goods.tips1')}}</div>
						<div class="publish-thumb">
							<div class="item" v-for="(item,index) in 5" :key="index">
								<div class="item-head" v-if="index==0"><em>*</em>{{$t('seller.goods.uploadTips1')}}</div>
								<div class="item-head" v-if="index==4">{{$t('seller.goods.uploadTips2')}}</div>
								<template v-if="goodsThumb[index] && goodsThumb[index].image_url">
									<div class="item-pic" @click="handleThumbOpen(index)"><img
											:src="goodsThumb[index].image_url" @error="imageLoadError" alt=""></div>
									<div class="item-bottom">
										<i v-if="index!==0" @click="handleMoveLeft(index)"
											class="el-icon-arrow-left"></i>
										<i class="el-icon-delete" @click="handleRemoveImages(index)"></i>
										<i v-if="index!==4" @click="handleMoveRight(index)"
											class="el-icon-arrow-right"></i>
									</div>
								</template>
								<div class="item-main" @click="handleUploadShow('thumb',index)" v-else>
									<i class="el-icon-plus"></i>
									<p>{{$t('seller.actions.addGoodsImage')}}</p>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.goodsCate')" prop="cate_id" ref="cate_id_item">
						<div class="section-tip">{{$t('seller.goods.tips2')}}</div>
						<div class="publish-cate-path">
							<span class="item-label">{{$t('seller.goods.checkCate')+':'}}</span>
							<div class="item-block">
								<el-breadcrumb separator-class="el-icon-arrow-right">
									<el-breadcrumb-item v-for="(item,index) in selectCate" :key="index"><span @click="handleCateSelect(item,index)">{{item.cate_name}}</span></el-breadcrumb-item>
								</el-breadcrumb>
							</div>
						</div>
					</el-form-item>
					<el-form-item>
						<div class="publish-cate-search">
							<el-input v-model="searchCateWork" @change="handleSearchCate" :placeholder="$t('seller.placeholder.cateSearch1')"></el-input>
							<el-popover
								placement="bottom-end"
								width="536"
								v-model="popoverSearchCate"
								trigger="click">
								<template v-if="searchCateList.length>0">
									<ul class="popper-cate-list">
										<li v-for="(item,index) in searchCateList" :key="index" @click="handleSearchCateSelect(item.cates)">
											<el-breadcrumb separator-class="el-icon-arrow-right">
												<el-breadcrumb-item v-for="(cate,cindex) in item.cates" :key="cindex"><span>{{cate.cate_name}}</span></el-breadcrumb-item>
											</el-breadcrumb>
										</li>
									</ul>
								</template>
								<template v-else>{{$t('seller.goods.text2-1')}}{{searchCateWork}}{{$t('seller.goods.text2-2')}}</template>
								<template slot="reference">
									<el-button @click="handleSearchCate">{{$t('seller.actions.search')}}</el-button>
								</template>
							</el-popover>
						</div>
						<div class="publish-cate-history">
							<span class="item-label">{{$t('seller.goods.text3')+':'}}</span>
							<div class="item-block">
								<span v-for="(item,index) in historyCate" :key="index">
									<template v-if="index!==0"> | </template>
									<a href="javascript:void(0);" @click="handleSearchCateSelect(item.cates)">{{item.cate_name}}</a>
								</span>
							</div>
						</div>
						<div class="publish-cate-lists">
							<div class="item" v-for="(item,index) in cateList" :key="index">
								<!-- <div class="item-head">
									<el-input :placeholder="$t('seller.placeholder.cateSearch2')">
										<i slot="suffix" class="el-input__icon el-icon-search"></i>
									</el-input>
								</div> -->
								<div class="item-main">
									<ul class="item-group">
										<li :class="isCateActive(cate.cate_id,index)?'active':''" v-for="cate in item" :key="cate.cate_id" @click="handleCateSelect(cate,index)">
											<p>{{cate.cate_name}}</p>
											<i class="el-icon-arrow-right"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</el-form-item>
				</div>
				<div class="publish-footer">
					<el-button type="primary" @click="handleNextStep">{{$t('seller.actions.nextGoods')}}</el-button>
				</div>
			</el-form>
			<el-form ref="goodsForm2" :model="goodsForm" :rules="goodsRules" label-width="160px" size="small" v-else-if="publishSteps === 2">
				<div class="publish-section">
					<div class="section-head"><h2>{{$t('seller.goods.title1')}}</h2></div>
					<el-form-item :label="$t('seller.formItem.goodsName')" prop="goods_name" ref="goods_name_item">
						<el-input v-model="goodsForm.goods_name" maxlength="60" clearable :placeholder="$t('seller.placeholder.maxLength60')"></el-input>
						<!-- <div class="section-tip">
							<p>
								<span>{{$t('seller.goods.tips3')}}</span>
								<el-button size="mini">{{$t('seller.actions.purity')}}</el-button>
								<el-button size="mini">{{$t('seller.actions.simplicity')}}</el-button>
							</p>
							<p>{{$t('seller.goods.tips4')}}</p>
						</div> -->
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.cateAttr')">
						<div class="section-tip">{{$t('seller.goods.tips5')}}</div>
						<div class="publish-cate-attr">
							<div class="item" v-for="(item,index) in goodsAttrs" :key="index">
								<div class="item-label">{{item.attr_name}}</div>
								<div class="item-block" v-if="item.attr_name=='适用对象'">
									<el-select v-model="item.attr_value" clearable multiple :multiple-limit="2">
										<el-option v-for="(apply,index2) in applyOption" :key="index2" :label="apply.label" :value="apply.value"></el-option>
									</el-select>
									<div class="item-msg"></div>
								</div>
								<div class="item-block" v-else-if="item.attr_name=='上市时间'">
									<el-date-picker
										v-model="item.attr_value"
										type="date"
										clearable
										value-format="yyyy-MM-dd"
										:placeholder="$t('seller.placeholder.selectDate')">
									</el-date-picker>
									<div class="item-msg"></div>
								</div>
								<div class="item-block" v-else>
									<el-input v-model="item.attr_value" clearable :placeholder="$t('seller.placeholder.default')"></el-input>
									<div class="item-msg"></div>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.goodsType')" prop="goods_type" ref="goods_type_item">
						<el-radio-group v-model="goodsForm.goods_type">
							<el-radio v-for="(item,index) in typeOption" :key="index" :label="item.value">{{item.label}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.purchaseFrom')" prop="purchase_from" ref="purchase_from_item">
						<el-radio-group v-model="goodsForm.purchase_from" @change="getFeetemplateList">
							<el-radio v-for="(item,index) in purchaseOption" :key="index" :label="item.value">{{item.label}}</el-radio>
						</el-radio-group>
						<el-popover
							trigger="hover">
							<p>{{$t('seller.popover.goodsPurchase')}}</p>
							<i slot="reference" class="uu-icon-question el-icon-question"></i>
						</el-popover>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.shopCate')">
						<div class="section-tip">{{$t('seller.goods.tips6')}}<router-link :to="{ name: 'decorateCategory'}" target="_blank">{{$t('seller.actions.viewShopCate')}}</router-link></div>
						<el-select v-model="shopCateIds" clearable multiple :multiple-limit="10" :placeholder="$t('seller.placeholder.selectCate')">
							<template v-for="item in shopCateList">
								<el-option :key="item.cate_id" :label="item.cate_name" :value="item.cate_id"></el-option>
								<template v-if="item.children && item.children.length>0">
									<el-option style="padding-left: 40px;" v-for="child in item.children" :key="child.cate_id" :label="child.cate_name" :value="child.cate_id"></el-option>
								</template>
							</template>
						</el-select>
					</el-form-item>
				</div>
				
				<div class="publish-section">
					<div class="section-head"><h2>{{$t('seller.goods.title2')}}</h2></div>
					<el-form-item :label="$t('seller.formItem.colorCate')">
						<div class="section-tip">
							<p><strong class="red">{{$t('seller.goods.tips7')}}</strong></p>
							<p>{{$t('seller.goods.tips8')}}</p>
						</div>
						<ul class="publish-color">
							<li v-for="(item,index) in checkColorList" :key="index">
								<el-select v-model="checkColorList[index]"  filterable allow-create :default-first-option="true" clearable @clear="handleColorClear" :placeholder="$t('seller.placeholder.goodsColor')">
									<el-option v-for="color in sellColorList" :key="color" :label="color" :value="color" :disabled="checkColorList.includes(color)"></el-option>
								</el-select>
							</li>
							<li>
								<el-select v-model="select_color" @change="handleColorChange" filterable allow-create :default-first-option="true" :placeholder="$t('seller.placeholder.goodsColor')">
									<el-option v-for="color in sellColorList" :key="color" :label="color" :value="color" :disabled="checkColorList.includes(color)"></el-option>
								</el-select>
							</li>
						</ul>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.goodsSize')">
						<div>
							<div class="uu-form-inline"><el-input v-model="size_value" :placeholder="$t('seller.placeholder.customValue')"></el-input></div>
							<div class="uu-form-inline"><el-button @click="handleAddSize">{{'+'+$t('seller.actions.add')}}</el-button></div>
						</div>
						<div class="publish-size">
							<el-checkbox-group v-model="checkSizeList">
								<div class="item" v-for="(item,index) in sellSizeList" :key="index">
									<el-checkbox :label="item"></el-checkbox>
									<template v-if="index>8">
										<el-popover
											placement="bottom"
											width="270"
											trigger="click"
											v-model="popoverSizeVisible">
											<i class="item-icon el-icon-edit-outline" slot="reference"></i>
											<div class="uu-form-inline w200"><el-input v-model="size_edit" :placeholder="$t('seller.placeholder.customValue')"></el-input></div>
											<div class="uu-form-inline mr0"><el-button @click="handleEditSize(index)">{{$t('seller.actions.confirm')}}</el-button></div>
										</el-popover>
										<i class="item-icon el-icon-delete" @click="handleDelSize(index)"></i>
									</template>
								</div>
							</el-checkbox-group>
						</div>
					</el-form-item>
					<template v-if="specsData.length>0">
						<el-form-item :label="$t('seller.formItem.goodsSpecs')" ref="goods_spus_item">
							<div style="padding-bottom: 10px;overflow: hidden;">
								<el-button type="primary" @click="handleBatchFill" style="float: right;">{{$t('seller.actions.batchFill')}}</el-button>
								<div class="section-tip">{{$t('seller.goods.tips9')}}</div>
							</div>
							<el-table
								border
								:data="specsData"
								:span-method="objectSpanMethod"
								style="width: 100%;">
								<el-table-column>
									<template slot="header" slot-scope="{}">
										<el-select v-model="batchForm.spec_1" :placeholder="$t('seller.placeholder.colorType')">
											<el-option
												v-for="item in checkColorList" :key="item"
												:label="item"
												:value="item">
											</el-option>
										</el-select>
									</template>
									<template slot-scope="scope">
										{{scope.row.spec_1}}
									</template>
								</el-table-column>
								<el-table-column>
									<template slot="header" slot-scope="{}">
										<el-select v-model="batchForm.spec_2" :placeholder="$t('seller.placeholder.size')">
											<el-option
												v-for="item in checkSizeList" :key="item"
												:label="item"
												:value="item">
											</el-option>
										</el-select>
									</template>
									<template slot-scope="scope">
										{{scope.row.spec_2}}
									</template>
								</el-table-column>
								<el-table-column :label="$t('seller.tableHead.colorCate')">
									<template slot-scope="scope">
										<template v-if="scope.row.img_url">
											<el-popover
												placement="right-start"
												width="200"
												trigger="hover">
												<img :src="scope.row.img_url" width="100%">
												<img :src="scope.row.img_url" slot="reference" class="specs-img">
											</el-popover>
											<a href="javascript:void(0);" class="specs-link" @click="handleUploadShow('specs',scope.$index)">{{$t('seller.actions.replace')}}</a>
											<a href="javascript:void(0);" class="specs-link" @click="handleDelSpecsImage(scope.$index)">{{$t('seller.actions.delete')}}</a>
										</template>
										<template v-else>
											<div class="specs-img"></div>
											<a href="javascript:void(0);" class="specs-link" @click="handleUploadShow('specs',scope.$index)">{{$t('seller.actions.selectImage')}}</a>
										</template>
									</template>
								</el-table-column>
								<el-table-column>
									<template slot="header" slot-scope="{}">
										<em>*</em><el-input v-model="batchForm.price" :placeholder="$t('seller.placeholder.price')" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"></el-input>
									</template>
									<template slot-scope="scope">
										<el-input v-model="scope.row.price" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"></el-input>
									</template>
								</el-table-column>
								<el-table-column>
									<template slot="header" slot-scope="{}">
										<em>*</em><el-input v-model="batchForm.stock" :placeholder="$t('seller.placeholder.stock')" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
									</template>
									<template slot-scope="scope">
										<el-input v-model="scope.row.stock" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
									</template>
								</el-table-column>
								<el-table-column>
									<template slot="header" slot-scope="{}">
										<el-input v-model="batchForm.sku" maxlength="64" :placeholder="$t('seller.placeholder.shopCode')"></el-input>
									</template>
									<template slot-scope="scope">
										<el-input v-model="scope.row.sku" maxlength="64"></el-input>
									</template>
								</el-table-column>
								<el-table-column>
									<template slot="header" slot-scope="{}">
										<el-input v-model="batchForm.bar_code" maxlength="32" :placeholder="$t('seller.placeholder.goodsCode')"></el-input>
									</template>
									<template slot-scope="scope">
										<el-input v-model="scope.row.bar_code" maxlength="32"></el-input>
									</template>
								</el-table-column>
							</el-table>
						</el-form-item>
					</template>
					<el-form-item :label="$t('seller.formItem.goodsPrice')" prop="price" ref="price_item">
						<div class="uu-form-inline"><el-input v-model="goodsForm.price" maxlength="18" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"></el-input></div>{{$t('seller.unit.yuan')}}
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.totalQuantity')" prop="stock" ref="stock_item">
						<div class="uu-form-inline"><el-input v-model="goodsForm.stock" min="0" max="100000000" maxlength="9" :disabled="goodsStockDisabled" oninput="value=value.replace(/[^\d]/g,'')"></el-input></div>{{$t('seller.unit.piece')}}
						<div class="section-tip">{{$t('seller.goods.tips10')}}</div>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.shopCode')">
						<el-input v-model="goodsForm.goods_code" maxlength="64" show-word-limit></el-input>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.goodsCode')">
						<el-input v-model="goodsForm.goods_bar_code" maxlength="32" show-word-limit></el-input>
					</el-form-item>
				</div>
				
				<div class="publish-section">
					<div class="section-head"><h2>{{$t('seller.goods.title3')}}</h2></div>
					<el-form-item :label="$t('seller.formItem.payMode')" prop="pay_mode" ref="pay_mode_item">
						<el-radio-group v-model="goodsForm.pay_mode">
							<el-radio v-for="(item,index) in payOptipn" :key="index" :label="item.value">{{item.label}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.stockCount')" prop="cal_stock" ref="cal_stock_item">
						<template slot="label">
							{{$t('seller.formItem.stockCount')}}
							<el-popover
								trigger="hover">
								<p>{{$t('seller.popover.stockCount1')}}</p>
								<p>{{$t('seller.popover.stockCount2')}}</p>
								<i slot="reference" class="uu-icon-question el-icon-question"></i>
							</el-popover>
						</template>
						<el-radio-group v-model="goodsForm.cal_stock">
							<el-radio v-for="(item,index) in deductOptipn" :key="index" :label="item.value">{{item.label}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.afterSale')">
						<div><el-checkbox v-model="goodsForm.provide_invoice" :true-label="1" :false-label="0">{{$t('seller.checkbox.invoice')}}</el-checkbox></div>
						<div><el-checkbox v-model="goodsForm.is_refund" :true-label="1" :false-label="0">{{$t('seller.checkbox.returnsPromise')}}</el-checkbox></div>
					</el-form-item>
				</div>
				
				<div class="publish-section">
					<div class="section-head"><h2>{{$t('seller.goods.title4')}}</h2></div>
					<el-form-item :label="$t('seller.formItem.extractionMode')" prop="freight_tpl_id" ref="freight_tpl_id_item">
						<div><el-checkbox v-model="goodsForm.extract_method" :true-label="1" :false-label="0">{{$t('seller.checkbox.logistics')}}</el-checkbox></div>
						<div class="publish-logis" v-if="goodsForm.extract_method==1">
							<div class="item-label">{{$t('seller.goods.text4')}}<em>*</em></div>
							<div class="item-block">
								<el-select v-model="goodsForm.freight_tpl_id" class="uu-form-inline">
									<el-option
										v-for="item in feetemplateList" :key="item.template_id"
										:label="item.template_name"
										:value="item.template_id">
									</el-option>
								</el-select>
								<el-button @click="onClickFeetemplate">{{$t('seller.actions.addFreightTpl')}}</el-button>
								<el-button @click="getFeetemplateList">{{$t('seller.actions.refreshTpl')}}</el-button>
							</div>
						</div>
					</el-form-item>
					<el-form-item>
						<el-checkbox v-model="goodsForm.deal_receipt" :true-label="1" :false-label="0">{{$t('seller.checkbox.payVoucher')}}</el-checkbox>
					</el-form-item>
				</div>
				
				<div class="publish-section">
					<div class="section-head"><h2>{{$t('seller.goods.title5')}}</h2></div>
					<el-form-item :label="$t('seller.formItem.goodsImagePc')" prop="goods_images" ref="goods_images_item">
						<div class="section-tip">{{$t('seller.goods.tips11')}} <!-- <a href="javascript:void(0);">{{$t('seller.actions.viewNorm')}}</a> --></div>
						<div class="publish-thumb">
							<div class="item" v-for="(item,index) in 5" :key="index">
								<div class="item-head" v-if="index==0"><em>*</em>{{$t('seller.goods.uploadTips1')}}</div>
								<template v-if="goodsThumb[index] && goodsThumb[index].image_url">
									<div class="item-pic" @click="handleThumbOpen(index)"><img :src="goodsThumb[index].image_url" @error="imageLoadError" alt=""></div>
									<div class="item-bottom">
										<i v-if="index!==0" @click="handleMoveLeft(index)" class="el-icon-arrow-left"></i>
										<i class="el-icon-delete" @click="handleRemoveImages(index)"></i>
										<i v-if="index!==4" @click="handleMoveRight(index)" class="el-icon-arrow-right"></i>
									</div>
								</template>
								<div class="item-main" @click="handleUploadShow('thumb',index)" v-else>
									<i class="el-icon-plus"></i>
									<p>{{$t('seller.actions.addGoodsImage')}}</p>
								</div>
							</div>
						</div>
					</el-form-item>
					<!-- <el-form-item :label="$t('seller.formItem.videoScale')">
						<el-radio-group v-model="goodsForm.video_scale">
							<el-radio v-for="(item,index) in scaleOption" :key="index" :label="item.value">{{item.label}}</el-radio>
						</el-radio-group>
					</el-form-item> -->
					<el-form-item :label="$t('seller.formItem.goodsVideo')">
						<div class="publish-video">
							<div class="uu-form-inline">
								<div class="publish-thumb" v-if="goodsForm.video_url">
									<div class="item">
										<div class="item-pic"><video :src="goodsForm.video_url" width="100px" height="100px"></video></div>
										<div class="item-bottom" style="text-align: center;">
											<i class="el-icon-delete" @click="handleRemoveVideo"></i>
										</div>
									</div>
								</div>
								<el-upload
									action="#"
									v-else
									v-loading="loadingUplaod"
									:show-file-list="false"
									accept="video/*"
									:http-request="httpRequestVideoUpload">
									<div class="upload-video">
										<i class="el-icon-video-camera"></i>
										<p>{{$t('seller.actions.selectVideo')}}</p>
									</div>
								</el-upload>
							</div>
							<div class="uu-form-inline">
								<div class="section-tip">{{$t('seller.goods.tips12')}}</div>
								<div class="section-tip">{{$t('seller.goods.tips13')}}</div>
								<div class="section-tip">{{$t('seller.goods.tips14')}}</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.goodsLong')">
						<div class="section-tip">{{$t('seller.goods.tips15')}}<!--<a>{{$t('seller.actions.viewDetails')}}</a>--></div>
						<div class="publish-thumb">
							<div class="item h150">
								<div class="item-head">{{$t('seller.goods.text5')}}</div>
								<template v-if="goodsForm.long_graph">
									<div class="item-pic" @click="dialogLongGraphVisible = true"><img :src="goodsForm.long_graph" @error="imageLoadError" alt=""></div>
									<div class="item-bottom" style="text-align: center;">
										<i class="el-icon-delete" @click="handleRemoveLogngImg"></i>
									</div>
								</template>
								<template v-else>
									<div class="item-main" @click="handleUploadShow('long')">
										<i class="el-icon-plus"></i>
										<p>{{$t('seller.actions.addGoodsImage')}}</p>
									</div>
								</template>
							</div>
						</div>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.description')" prop="description" ref="description_item">
						<tinymce ref="editor" v-model="goodsForm.description" :image="true" :height="400"/>
					</el-form-item>
					
					<el-form-item :label="$t('seller.formItem.mobileContent')">
						<div class="publish-meditor">
							<div class="meditor-head">
								<div class="head-bar">
									<el-popover
										width="180"
										placement="bottom-start"
										trigger="hover">
										<p style="margin-bottom: 10px;">{{$t('seller.popover.mobileContent')}}</p>
										<div style="text-align: center;"><el-button type="primary" @click="handlEmeditorImport">{{$t('seller.actions.confirmCreate')}}</el-button></div>
										<el-button size="small" slot="reference">{{$t('seller.actions.importDesc')}}</el-button>
									</el-popover>
								</div>
							</div>
							<div class="meditor-content">
								<div class="meditor-body">
									<div class="item" v-if="meditorRemark">
										<div class="item-main summary" v-show="!meditorRemarkFlag"><i class="el-icon-s-opportunity"></i>{{meditorRemark}}</div>
										<div class="item-tools" v-show="!meditorRemarkFlag">
											<div class="tools-bg"></div>
											<div class="tools-btns">
												<div class="tools-btn" @click="handelWpEditorAddImg(0)">{{$t('seller.actions.addImage')}}</div>
												<div class="tools-btn" @click="handelWpEditorEditRemark">{{$t('seller.actions.edit')}}</div>
												<div class="tools-btn" @click="handelWpEditorDelRemark">{{$t('seller.actions.delete')}}</div>
											</div>
										</div>
										<div class="item-input" v-show="meditorRemarkFlag">
											<el-input type="textarea" v-model="meditorRemarkNews" size="small"></el-input>
											<div class="mt15">
												<el-button type="primary" size="small" @click="handleWpEditorRemarkConfirm">{{$t('seller.actions.confirm2')}}</el-button>
												<el-button size="small" @click="handleWpEditorRemarkCancel">{{$t('seller.actions.cancel')}}</el-button>
											</div>
										</div>
									</div>
									<div class="item" v-for="item,index in meditorList" :key="index">
										<!-- <div class="item-main summary"><i class="el-icon-s-opportunity"></i>1222</div> -->
										<div class="item-main" v-html="item" v-show="!wpEditorEdit.includes(index)"></div>
										<div class="item-tools" v-show="!wpEditorEdit.includes(index)">
											<div class="tools-bg"></div>
											<div class="tools-btns">
												<div class="tools-btn" @click="handelWpEditorUp(index)" v-if="index!==0">{{$t('seller.actions.moveUp')}}</div>
												<div class="tools-btn" @click="handelWpEditorDown(index)" v-if="index!==meditorList.length-1">{{$t('seller.actions.moveDown')}}</div>
												<div class="tools-btn" @click="handelWpEditorAddImg(index)">{{$t('seller.actions.addImage')}}</div>
												<div class="tools-btn" @click="handelWpEditorEditImg(index)" v-if="item.search(/<img /i)>=0">{{$t('seller.actions.replace')}}</div>
												<div class="tools-btn" @click="handelWpEditorEditText(index)" v-else>{{$t('seller.actions.edit')}}</div>
												<div class="tools-btn" @click="handelWpEditorDel(index)">{{$t('seller.actions.delete')}}</div>
											</div>
										</div>
										<div class="item-input" v-show="wpEditorEdit.includes(index)">
											<el-input type="textarea" v-model="wpEditorList[index]" size="small"></el-input>
											<div class="mt15">
												<el-button type="primary" size="small" @click="handleWpEditorConfirm(index)">{{$t('seller.actions.confirm2')}}</el-button>
												<el-button size="small" @click="handleWpEditorCancel(index)">{{$t('seller.actions.cancel')}}</el-button>
											</div>
										</div>
									</div>
									<div class="item" v-if="wpEditorTextFlag">
										<div class="item-input">
											<el-input type="textarea" v-model="wpEditorText" size="small"></el-input>
											<div class="mt15">
												<el-button type="primary" size="small" @click="handleWpEditorTextConfirm">{{$t('seller.actions.confirm2')}}</el-button>
												<el-button size="small" @click="handleWpEditorTextCancel">{{$t('seller.actions.cancel')}}</el-button>
											</div>
										</div>
									</div>
								</div>
								<div class="meditor-footer">
									<div class="footnav navadd">
										<div class="item">
											<div class="item-icon"><i class="el-icon-plus"></i></div>
											<div class="item-text">{{$t('seller.actions.add')}}</div>
										</div>
									</div>
									<div class="footnav navrow">
										<div class="item" @click="handelWpEditorAddText('remark')">
											<div class="item-icon"><i class="el-icon-plus"></i></div>
											<div class="item-text">{{$t('seller.actions.addAbstract')}}</div>
										</div>
										<div class="item" @click="handelWpEditorAddImg">
											<div class="item-icon"><i class="el-icon-picture"></i></div>
											<div class="item-text">{{$t('seller.actions.addPicture')}}</div>
										</div>
										<div class="item" @click="handelWpEditorAddText('text')">
											<div class="item-icon"><i class="el-icon-plus"></i></div>
											<div class="item-text">{{$t('seller.actions.addText')}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.shelvesTime')" prop="is_show" ref="is_show_item">
						<!-- <div class="section-tip">{{$t('seller.goods.tips16')}}</div> -->
						<div>
							<el-radio-group v-model="goodsForm.is_show">
								<el-radio v-for="(item,index) in shelvesOption" :key="index" :label="item.value">{{item.label}}</el-radio>
							</el-radio-group>
						</div>
					</el-form-item>
				</div>
				
				<div class="publish-footer">
					<template v-if="isEdit">
						<el-button @click="handleGoodsPublish" type="primary">{{$t('seller.actions.submitGoods')}}</el-button>
					</template>
					<template v-else>
						<el-button @click="publishSteps = 1">{{$t('seller.actions.prevGoods')}}</el-button>
						<el-button @click="handleGoodsPublish" type="primary">{{$t('seller.actions.publish')}}</el-button>
					</template>
				</div>
			</el-form>
			
			<el-dialog
				:title="$t('seller.dialogTitle.image')"
				class="dialog-upload"
				width="960px"
				top="32vh"
				:modal="false"
				:visible.sync="dialogUploadVisible"
				:before-close="handleUploadClose">
				<upload-material
					ref="uploadImage"
					@get-uplod-action="handleUploadAction"
					:upload-type="false"
					:upload-action="'goods'">
				</upload-material>
			</el-dialog>
			
			<el-dialog
				width="540px"
				:visible.sync="dialogThumbVisible"
				:before-close="handleThumbClose"
				@open="dialogThumbOpen">
				<el-carousel ref="thumbCarousel" height="500px" :initial-index="thumbIndex" :autoplay="false" arrow="always">
					<el-carousel-item v-for="(item,index) in goodsThumb" name="index" :key="index">
						<img :src="item.image_url" alt="" width="100%" height="100%" style="object-fit: contain;">
					</el-carousel-item>
				</el-carousel>
			</el-dialog>
			
			<el-dialog
				width="540px"
				:visible.sync="dialogLongGraphVisible">
				<img :src="goodsForm.long_graph" alt="" width="500px" height="500px" style="object-fit: contain;">
			</el-dialog>
			
		</div>
	</el-scrollbar>
</template>

<script>
	import tinymce from '@/components/Tinymce'
	import uploadMaterial from '@/components/seller/uploadMaterial.vue'
	import { normalOSSUpload } from '@/utils/upload'
	import { categoryList, categoryShop, categoryHistory, searchCategory } from '@/api/seller/category'
	import { fetchGoods, createGoods, updateGoods } from '@/api/seller/goods'
	import { freightTplList } from '@/api/seller/logistics'
	
	const defaultForm = {
		goods_images: [],
		cate_id: 0,
		goods_name: '',
		goods_type: '1',
		purchase_from: '1',
		store_cate_ids: '',
		price: '',
		stock: 1,
		goods_code: '',
		goods_bar_code: '',
		pay_mode: '1',
		cal_stock: '1',
		provide_invoice: 0,
		is_refund: 1,
		extract_method: 0,
		freight_tpl_id: '',
		deal_receipt: 0,
		// video_scale: '1',
		video_url: '',
		long_graph: '',
		description: '',
		mobile_content: '',
		is_show: 1,
		spec_name_1: '颜色',
		spec_name_2: '尺码',
	}
	const defaultAttrs = [
		{ attr_name: '货号', attr_value: '' },
		{ attr_name: '适用对象', attr_value: [] },
		{ attr_name: '品牌', attr_value: '' },
		{ attr_name: '上市时间', attr_value: '' },
		{ attr_name: '功能', attr_value: '' },
		{ attr_name: '用途', attr_value: '' },
	]
	const defaultColor = ['白色','灰色','黑色','红色','黄色','绿色','蓝色','紫色','棕色','花色','透明']
	const defaultSize = ['XS','S','M','L','XL','XXL','XXXL','均码','其他尺码']
	const defaultBatch = { spec_1: '', spec_2: '', price: '', stock: '', sku: '', bar_code: '' }
	
	export default {
		name: 'GoodsDetail',
		components: {
			uploadMaterial,
			tinymce
		},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			}
		},
		data() {
			var self = this;
			var validateImages = (rule, value, callback)=>{
				if(value.length>0){
					callback();
				}else {
					callback(new Error(self.$t('seller.validator.required')));
				}
			}
			var validateCateId = (rule, value, callback)=>{
				if(parseInt(value)>0){
					if(self.selectCate.length <2 && !self.goodsForm.goods_id > 0) {
						callback(new Error(self.$t('seller.validator.cateMin')));
					} else {
						callback();
					}
				}else {
					callback(new Error(self.$t('seller.validator.required')));
				}
			}
			var validateName = (rule, value, callback)=>{
				if(value === ''){
					callback(new Error(self.$t('seller.validator.required')));
				}else {
					var leng = 0;
					for(let i=0;i<value.length;i++){
						var c= value.charCodeAt(i);
						if((c>=0x001 && c<=0x007e) || (0xff60<=c && c<=0xff9f)){
							leng++;
						}else {
							leng+=2;
						}
					}
					if(leng>60){
						callback(new Error(self.$t('seller.validator.maxLength60')));
					}else{
						callback();
					}
				}
			}
			var validatePrice = (rule, value, callback)=>{
				if(value === '' || parseFloat(value)<=0){
					callback(new Error(self.$t('seller.validator.minNum')+'0!'));
				}else if(parseFloat(value)>10000000){
					callback(new Error(self.$t('seller.validator.maxNum')+'10000000!'));
				}else{
					callback();
				}
			}
			var validateStock = (rule, value, callback)=>{
				if(value === '' || parseInt(value)<1){
					callback(new Error(self.$t('seller.validator.minNum')+'1!'));
				}else if(parseInt(value)>100000000){
					callback(new Error(self.$t('seller.validator.maxNum')+'10000000!'));
				}else{
					callback();
				}
			}
			var validateExtractMethod = (rule, value, callback)=>{
				if(value>0){
					callback();
				}else {
					callback(new Error(self.$t('seller.validator.freightTpl')));
				}
			}
			return {
				stepsOption: this.$t('seller.goods.stepsOption'),
				publishSteps: 1,
				goodsForm: Object.assign({}, defaultForm),
				goodsRules: {
					goods_images: [{ required: true, validator: validateImages, trigger: 'blur' }],
					cate_id: [{ required: true, validator: validateCateId, trigger: 'blur' }],
					goods_name: [
						{ required: true, validator: validateName, trigger: 'blur' }
					],
					goods_type: [
						{ required: true, message: this.$t('seller.validator.required'), trigger: 'change' }
					],
					purchase_from: [
						{ required: true, message: this.$t('seller.validator.required'), trigger: 'change' }
					],
					price: [
						{ required: true,  validator: validatePrice, trigger: 'blur' }
					],
					stock: [
						{ required: true, validator: validateStock, trigger: 'blur' }
					],
					pay_mode: [
						{ required: true, message: this.$t('seller.validator.required'), trigger: 'change' }
					],
					cal_stock: [
						{ required: true, message: this.$t('seller.validator.required'), trigger: 'change' }
					],
					freight_tpl_id: [
						{ required: true, message: this.$t('seller.validator.required'), trigger: 'change' },
						{ validator: validateExtractMethod, trigger: 'change' },
					],
					description: [
						{ required: true, message: this.$t('seller.validator.required'), trigger: 'blur' }
					],
					is_show: [
						{ required: true, message: this.$t('seller.validator.required'), trigger: 'change' }
					]
				},
				shopCateIds: [],
				dialogUploadVisible: false,
				goodsThumb: [{},{},{},{},{}],
				uploadType: '',
				uploadIndex: '',
				searchCateWork: '',
				popoverSearchCate: false,
				searchCateList: [],
				cateList:[],
				selectCate: [],
				historyCate: [],
				goodsAttrs: Object.assign([], defaultAttrs),
				applyOption: Object.assign([], this.$t('seller.goods.applyOption')),
				typeOption: Object.assign([], this.$t('seller.goods.typeOption')),
				purchaseOption: Object.assign([], this.$t('seller.goods.purchaseOption')),
				payOptipn: Object.assign([], this.$t('seller.goods.payOptipn')),
				deductOptipn: Object.assign([], this.$t('seller.goods.deductOptipn')),
				scaleOption: Object.assign([], this.$t('seller.goods.scaleOption')),
				shelvesOption: Object.assign([], this.$t('seller.goods.shelvesOption')),
				shopCateList: [],
				sellColorList: Object.assign([], defaultColor),
				select_color: '',
				select_color_desc: '',
				checkColorList: [],
				sellSizeList: Object.assign([], defaultSize),
				popoverSizeVisible: false,
				size_value: '',
				size_edit: '',
				checkSizeList: [],
				specsData: [],
				goodsStockDisabled: false,
				batchForm: Object.assign({}, defaultBatch),
				loadingUplaod: false,
				feetemplateList: [],
				meditorList: [],
				meditorRemark: '',
				meditorRemarkNews: '',
				meditorRemarkFlag: false,
				wpEditorList: [],
				wpEditorEdit: [],
				wpEditorText: '',
				wpEditorTextType: '',
				wpEditorTextFlag: false,
				
				thumbIndex: 0,
				dialogThumbVisible: false,
				dialogLongGraphVisible: false,
			}
		},
		created() {
			this.getCateList(0,-1);	// 平台类目
			this.getShopCateList(); // 店铺类目
			this.getHistoryCate(); // 历史类目
			if(this.isEdit){
				this.publishSteps = 2;
				this.getGoodsInfo();
			}else {
				this.getFeetemplateList();
			}
		},
		methods: {
			imageLoadError(e){
				e.target.src = this.$imageErrorGoods;
			},
			getCateList(pid=0,level=-1){
				let params = {
					is_platform: 1,
					parent_id: pid
				}
				categoryList(params).then(response => {
					this.cateList.splice(level);
					if(response.data.list.length>0){
						this.cateList.push(response.data.list);
					}
				})
			},
			getShopCateList(){
				categoryShop({is_platform: 0}).then(response => {
					this.shopCateList = response.data.list
				})
			},
			getHistoryCate(){
				categoryHistory({uuid: this.$store.getters.uuid}).then(response => {
					this.historyCate = response.data.list
				})
			},
			getGoodsInfo() {
				let loading = this.$loading()
				fetchGoods({goods_id: this.$route.params.id}).then(response => {
					this.goodsForm = JSON.parse(JSON.stringify(response.data))
					this.getFeetemplateList()
					this.onRefreshGoodsData(response.data)
					loading.close()
				}).catch(() => {
					loading.close()
					this.$router.push({name: 'sellManage'});
				})
			},
			getFeetemplateList(){
				let params = {
					type: this.goodsForm.purchase_from,
					page_index: 0,
					page_size: 100
				}
				freightTplList(params).then(response => {
					this.feetemplateList = response.data.list
					this.goodsForm.freight_tpl_id = response.data.list[0] ? response.data.list[0].template_id : ''
				})
			},
			onRefreshGoodsData(data){
				this.goodsForm.pay_mode = data.pay_mode?data.pay_mode:'1';
				this.goodsForm.cal_stock = data.cal_stock?data.cal_stock:'1';
				this.goodsForm.is_refund = data.is_refund?data.is_refund:0;
				this.goodsForm.extract_method = data.extract_method?data.extract_method:0;
				data.goods_images.forEach((item,object)=>{
					this.goodsThumb.splice(object,1,item);
				})
				if(data.goods_attrs.length>0){
					this.goodsAttrs = data.goods_attrs;
					this.goodsAttrs.forEach(item=>{
						if(item.attr_name=='适用对象' && item.attr_value!==''){
							if(typeof item.attr_value!=='string'){
								item.attr_value = item.attr_value.toString();
							}
							let attr_arr = item.attr_value.split(',');
							if(attr_arr.length>0){
								item.attr_value= attr_arr.map(function(data){
									return +data;
								});
							}
						}
					})
				}
				this.selectCate = data.cates_list;
				this.specsData = data.goods_specs;
				let shop_cate = data.store_cate_ids?data.store_cate_ids.split(','):[];
				if(shop_cate.length>0){
					this.shopCateIds = shop_cate.map(function(data){
						return +data;
					});
				}
				let checkColor = [],checkSize = [];
				data.goods_specs.forEach(item=>{
					if(!checkColor.includes(item.spec_1)){
						checkColor.push(item.spec_1);
					}
					if(!this.sellColorList.includes(item.spec_1)){
						this.sellColorList.push(item.spec_1);
					}
					if(!checkSize.includes(item.spec_2)){
						checkSize.push(item.spec_2);
					}
					if(!this.sellSizeList.includes(item.spec_2)){
						this.sellSizeList.push(item.spec_2);
					}
				})
				this.checkColorList = checkColor;
				this.checkSizeList = checkSize;
				let mobileContent = data.mobile_content?JSON.parse(data.mobile_content):[];
				this.meditorRemark = mobileContent.remark?mobileContent.remark:'';
				this.meditorList = mobileContent.list?JSON.parse(JSON.stringify(mobileContent.list)):[];
				this.wpEditorList = mobileContent.list?JSON.parse(JSON.stringify(mobileContent.list)):[];
			},
			handleUploadShow(type,index=-1){
				this.uploadType = type?type:'';
				this.uploadIndex = index;
				this.dialogUploadVisible = true;
			},
			handleUploadClose(){
				this.dialogUploadVisible = false;
			},
			handleUploadAction(data){
				if(this.uploadType==='thumb'){
					let thumbs = [];
					for(let i=0;i<this.goodsThumb.length;i++){
						if(this.goodsThumb[i].image_url){
							thumbs.push(this.goodsThumb[i].image_url);
						}
					}
					if(thumbs.includes(data)){
						this.$message.error(this.$t('seller.errorMsg.repeatSelect'));
						return false;
					}
					let item = {image_id:0,image_url:data};
					if(this.uploadIndex>=0){
						this.$set(this.goodsThumb,this.uploadIndex,item);
						this.uploadIndex = -1;
					}else {
						let flag = 0;
						for(let i=0;i<5;i++){
							if(flag!==1 && !this.goodsThumb[i].image_url){
								this.$set(this.goodsThumb,i,item)
								flag = 1;
							}else if(flag==0&&i==4){
								this.dialogUploadVisible = false;
								this.$message.error(this.$t('seller.errorMsg.maxAddImage'));
								return false;
							}
						}
					}
					this.$refs.goods_images_item.clearValidate();
				}else if(this.uploadType==='specs'){
					let color = this.specsData[this.uploadIndex].spec_1;
					this.specsData.forEach(item=>{
						if(item.spec_1==color){
							item.img_url = data;
						}
					})
					this.dialogUploadVisible = false;
				}else if(this.uploadType==='long'){
					this.goodsForm.long_graph = data;
					this.dialogUploadVisible = false;
				}else if(this.uploadType==='wpEditorAdd'){
					let item = '<img src="'+data+'" />';
					if(this.uploadIndex>=0){
						this.meditorList.splice(this.uploadIndex+1,0,item);
						this.wpEditorList.splice(this.uploadIndex+1,0,item);
					}else{
						this.meditorList.push(item);
						this.wpEditorList.push(item);
					}
					this.dialogUploadVisible = false;
				}else if(this.uploadType==='wpEditorEdit'){
					let item = '<img src="'+data+'" />';
					if(this.uploadIndex>=0){
						this.meditorList[this.uploadIndex] = item;
						this.wpEditorList[this.uploadIndex] = item;
					}
					this.dialogUploadVisible = false;
				}
			},
			handleRemoveImages(index){
				this.$set(this.goodsThumb,index,{});
			},
			handleRemoveLogngImg(){
				this.goodsForm.long_graph = '';
			},
			handleRemoveVideo(){
				this.goodsForm.video_url = '';
			},
			handleMoveLeft(index){
				if(index>0){
					let image = this.goodsThumb;
					this.goodsThumb[index]=image.splice(index-1,1,image[index])[0];
				}
			},
			handleMoveRight(index){
				if(index!==4){
					let image = this.goodsThumb;
					this.goodsThumb[index]=image.splice(index+1,1,image[index])[0];
				}
			},
			handleThumbClose(){
				this.dialogThumbVisible = false;
			},
			dialogThumbOpen(){
				this.$nextTick(function(){
					this.$refs.thumbCarousel.setActiveItem(this.thumbIndex);
				})
			},
			handleThumbOpen(index){
				this.thumbIndex = index;
				this.dialogThumbVisible = true;
			},
			handleSearchCate(){
				if(this.searchCateWork){
					let params = {
						is_platform: 1,
						keyword: this.searchCateWork
					}
					searchCategory(params).then(response => {
						this.popoverSearchCate = true;
						this.searchCateList = response.data.list
					})
				}
			},
			handleSearchCateSelect(data){
				for(let i=0;i<data.length;i++){
					this.selectCate.splice(i);
					this.getCateList(data[i].cate_id,i+1);
					this.selectCate.push(data[i]);
					this.goodsForm.cate_id = data[i].cate_id;
				}
				this.$refs.cate_id_item.clearValidate();
				this.popoverSearchCate = false;
			},
			handleCateSelect(data,level){
				this.selectCate.splice(level);
				this.getCateList(data.cate_id,level+1);
				this.selectCate.push(data);
				this.goodsForm.cate_id = data.cate_id;
				this.$refs.cate_id_item.clearValidate();
			},
			isCateActive(id,index){
				if(this.selectCate[index] && this.selectCate[index].cate_id == id){
					return true;
				}else{
					return false;
				}
			},
			handleNextStep(){
				let goodsImage = [];
				this.goodsThumb.forEach(item=>{
					if(item.image_url){
						goodsImage.push(item);
					}
				})
				if(goodsImage.length>0){
					this.goodsForm.default_image = goodsImage[0]['image_url'];
					this.goodsForm.goods_images = goodsImage;
				}
				this.$refs.goodsForm1.validate((valid,object) => {
					if (valid) {
						for(let i =0;i<5;i++){
							if(goodsImage[i] && goodsImage[i].image_url){
								this.goodsThumb[i] = goodsImage[i];
							}else {
								this.goodsThumb[i] = {};
							}
						}
						this.publishSteps = 2;
					} else {
						for (let i in object) {
							this.$refs[i+'_item'].$el.scrollIntoView({
									block: 'center',
									behavior: 'smooth'
							})
							break;
						}
						return false;
					}
				});
			},
			
			handleColorChange(val){
				if(!this.sellColorList.includes(val)){
					this.sellColorList.push(val);
				}
				this.checkColorList.push(val);
				this.select_color = '';
			},
			handleColorClear(){
				this.checkColorList.forEach((item,index)=>{
					if(!item){
						this.checkColorList.splice(index,1);
					}
				})
			},
			handleAddSize(){
				if(this.size_value){
					if(this.sellSizeList.indexOf(this.size_value)<0){
						this.sellSizeList.push(this.size_value);
						this.checkSizeList.push(this.size_value);
						this.size_value = '';
					}else {
						this.$message.error(this.$t('seller.errorMsg.customValue'));
					}
				}
			},
			handleEditSize(index){
				if(this.size_edit){
					let n = this.sellSizeList.indexOf(this.size_edit);
					if(n<0){
						let size = this.sellSizeList[index];
						this.popoverSizeVisible = false;
						this.sellSizeList[index]=this.size_edit;
						if(this.checkSizeList.indexOf(size)>-1){
							this.checkSizeList[this.checkSizeList.indexOf(size)]=this.size_edit;
							this.specsData.forEach(item=>{
								if(item.spec_2==size){
									item.spec_2 = this.size_edit;
								}
							})
						}
						this.size_edit = '';
					}else if(n>-1 && n!==index) {
						this.$message.error(this.$t('seller.errorMsg.customValue'));
					}
				}else {
					this.popoverSizeVisible = false;
				}
			},
			handleDelSize(index){
				this.$confirm(this.$t('seller.confirm.deleteText4')+this.sellSizeList[index]+this.$t('seller.confirm.deleteText2'),'',{
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					let size = this.sellSizeList[index];
					if(this.checkSizeList.indexOf(size)>-1){
						this.checkSizeList.splice(this.checkSizeList.indexOf(size),1);
					}
					this.sellSizeList.splice(index,1);
				})
			},
			getAssembleList(){
				let specsParam = [];
				let specsItem = [];
				if(this.checkColorList.length>0){
					specsItem.push('spec_1');
					specsParam.push(this.checkColorList);
				}
				if(this.checkSizeList.length>0){
					specsItem.push('spec_2');
					specsParam.push(this.checkSizeList);
				}
				let result = this.descartes(specsParam);
				let specsData = [];
				for(let i=0;i<result.length;i++){
					let item = {img_url:'',price:'',stock:'0',sku:'',bar_code: ''};
					for (let j = 0; j < specsItem.length; j++) {
						item[specsItem[j]] = result[i][j];
					}
					for (let j = 0; j < this.specsData.length; j++){
						if(this.specsData[j].spec_1 == item.spec_1 && this.specsData[j].spec_2 == item.spec_2){
							item = this.specsData[j];
						}
					}
					specsData.push(item);
				}
				if(specsData.length>0){
					// this.goodsForm.stock = 0;
					this.goodsStockDisabled = true;
				}else {
					this.goodsStockDisabled = false;
				}
				this.specsData = specsData;
			},
			descartes: function(array){
				if( array.length < 2 ) return [];
				return [].reduce.call(array, function(col, set) {
					var res = [];
					col.forEach(function(c) {
						set.forEach(function(s) {
							var t = [].concat( Array.isArray(c) ? c : [c] );
							t.push(s);
							res.push(t);
						})
					});
					return res;
				});
			},
			objectSpanMethod({ row, column, rowIndex, columnIndex }){
				if(columnIndex === 0){
					let n = this.checkSizeList.length;
					if(rowIndex % n === 0){
						return [n,1];
					}else{
						return [0,0];
					}
				}
			},
			handleBatchFill(){
				let skusums = 0;
				let price = this.batchForm.price?this.batchForm.price:this.specsData[0].price;
				this.specsData.forEach(item=>{
					if(this.batchForm.spec_1 && this.batchForm.spec_2){
						if(item.spec_1==this.batchForm.spec_1 && item.spec_2==this.batchForm.spec_2){
							item.price = this.batchForm.price?this.batchForm.price:item.price;
							item.stock = this.batchForm.stock?this.batchForm.stock:item.stock;
							item.sku = this.batchForm.sku?this.batchForm.sku:item.sku;
							item.bar_code = this.batchForm.bar_code?this.batchForm.bar_code:item.bar_code;
						}
					}else if(!this.batchForm.spec_1 && this.batchForm.spec_2){
						if(item.spec_2==this.batchForm.spec_2){
							item.price = this.batchForm.price?this.batchForm.price:item.price;
							item.stock = this.batchForm.stock?this.batchForm.stock:item.stock;
							item.sku = this.batchForm.sku?this.batchForm.sku:item.sku;
							item.bar_code = this.batchForm.bar_code?this.batchForm.bar_code:item.bar_code;
						}
					}else if(this.batchForm.spec_1 && !this.batchForm.spec_2){
						if(item.spec_1==this.batchForm.spec_1){
							item.price = this.batchForm.price?this.batchForm.price:item.price;
							item.stock = this.batchForm.stock?this.batchForm.stock:item.stock;
							item.sku = this.batchForm.sku?this.batchForm.sku:item.sku;
							item.bar_code = this.batchForm.bar_code?this.batchForm.bar_code:item.bar_code;
						}
					}else{
						item.price = this.batchForm.price?this.batchForm.price:item.price;
						item.stock = this.batchForm.stock?this.batchForm.stock:item.stock;
						item.sku = this.batchForm.sku?this.batchForm.sku:item.sku;
						item.bar_code = this.batchForm.bar_code?this.batchForm.bar_code:item.bar_code;
					}
					if(item.stock>0){
							skusums+=parseInt(item.stock);
					}
					if(item.price && parseFloat(item.price)>0 && parseInt(item.stock)>0){
						if(parseFloat(price)>parseFloat(item.price)){
							price = item.price;
						}
					}
				})
				this.goodsForm.stock = skusums;
				this.goodsForm.price = price;
				this.batchForm.spec_1 = '';
				this.batchForm.spec_2 = '';
				this.batchForm.price = '';
				this.batchForm.stock = '';
				this.batchForm.sku = '';
				this.batchForm.bar_code = '';
			},
			getTotalSku(){
				let total = 0 ;
				if(this.specsData.length>0){
					let price = 0;
					for (let i = 0; i < this.specsData.length; i++) {
						if(this.specsData[i].stock>0){
							total+= parseInt(this.specsData[i].stock);
							if(parseFloat(price)>0){
								if(parseFloat(this.specsData[i].price)<parseFloat(price)){
									price = this.specsData[i].price;
								}
							}else{
								price = parseFloat(this.specsData[i].price);
							}
						}
					}
					if(price>0){
						this.goodsForm.price = price;
					}
				}else {
					total = 1;
				}
				this.goodsForm.stock = total;
			},
			handleDelSpecsImage(index){
				let spec_1 = this.specsData[index].spec_1;
				this.specsData.forEach(item=>{
					if(item.spec_1==spec_1){
						item.img_url = '';
					}
				})
			},
			async httpRequestVideoUpload(value){
				const { file } = value
				const istype = file.type.indexOf('video')
				const isSize = file.size / 1024 / 1024 < 5
				if (istype<0) {
					this.$message.error(this.$t('seller.upload.videoType'))
					return false
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.videoSize5M'))
					return false
				}
				this.loadingUplaod = true;
				const { code, data } = await normalOSSUpload(file,'video')
				if(code==200){
					this.goodsForm.video_url = data.url
				}else{
					this.$message.error(this.$t('seller.errorMsg.upload'))
				}
				this.loadingUplaod = false
			},
			handlEmeditorImport(){
				let string = this.goodsForm.description.replace(/<(?!img)[^>]*>/g,"\n");
				let arr = string.split("\n");
				let list = [];
				arr.forEach(item=>{
					if(item){
						let child = item.split(/(<.*?>)/);
						child.forEach(obj=>{
							if(obj){
								list.push(obj);
							}
						})
					}
				})
				this.meditorList = JSON.parse(JSON.stringify(list));
				this.wpEditorList = JSON.parse(JSON.stringify(list));
			},
			handelWpEditorEditRemark(){
				this.meditorRemarkFlag = true;
				this.meditorRemarkNews = this.meditorRemark;
			},
			handelWpEditorDelRemark(){
				this.meditorRemark = '';
			},
			handleWpEditorRemarkConfirm(){
				this.meditorRemark = this.meditorRemarkNews;
				this.meditorRemarkFlag = false;
			},
			handleWpEditorRemarkCancel(){
				this.meditorRemarkNews = this.meditorRemark;
				this.meditorRemarkFlag = false;
			},
			handelWpEditorUp(index){
				if(index>0){
					let list = this.meditorList;
					this.meditorList[index]=list.splice(index-1,1,list[index])[0];
					this.wpEditorList=JSON.parse(JSON.stringify(this.meditorList));
				}
			},
			handelWpEditorDown(index){
				if(index<this.meditorList.length){
					let list = this.meditorList;
					this.meditorList[index]=list.splice(index+1,1,list[index])[0];
					this.wpEditorList=JSON.parse(JSON.stringify(this.meditorList));
				}
			},
			handelWpEditorAddImg(index=-1){
				this.uploadIndex = index;
				this.uploadType = 'wpEditorAdd';
				this.dialogUploadVisible = true;
			},
			handelWpEditorEditImg(index=-1){
				this.uploadIndex = index;
				this.uploadType = 'wpEditorEdit';
				this.dialogUploadVisible = true;
			},
			handelWpEditorAddText(type){
				this.wpEditorTextType = type;
				this.wpEditorTextFlag = true;
			},
			handelWpEditorEditText(index){
				this.wpEditorEdit.push(index);
			},
			handelWpEditorDel(index){
				this.meditorList.splice(index,1);
				this.wpEditorList.splice(index,1);
			},
			handleWpEditorConfirm(index){
				this.wpEditorEdit.splice(this.wpEditorEdit.indexOf(index),1);
				let list = JSON.parse(JSON.stringify(this.wpEditorList));
				this.meditorList[index] = list[index];
			},
			handleWpEditorCancel(index){
				this.wpEditorEdit.splice(this.wpEditorEdit.indexOf(index),1);
				let list = JSON.parse(JSON.stringify(this.meditorList));
				this.wpEditorList[index] = list[index];
			},
			handleWpEditorTextConfirm(){
				if(this.wpEditorText){
					if(this.wpEditorTextType==='text'){
						this.meditorList.push(this.wpEditorText);
						this.wpEditorList.push(this.wpEditorText);
					}else if(this.wpEditorTextType==='remark'){
						this.meditorRemark = this.wpEditorText;
					}
					this.wpEditorText = '';
					this.wpEditorTextFlag = false;
				}else {
					this.$message.error(this.$t('seller.errorMsg.input'));
				}
			},
			handleWpEditorTextCancel(){
				this.wpEditorText = '';
				this.wpEditorTextFlag = false;
			},
			handleGoodsPublish(){
				let loading = this.$loading();
				let goodsImage = [];
				this.goodsThumb.forEach((item,object)=>{
					if(item&&item.image_url){
						item.sort_order = object;
						goodsImage.push(item);
					}
				})
				this.goodsForm.default_image = goodsImage.length>0?goodsImage[0]['image_url']:'';
				this.goodsForm.goods_images = goodsImage;
				let goods_attrs = JSON.parse(JSON.stringify(this.goodsAttrs));
				for(let i=0;i<goods_attrs.length;i++){
					if(Array.isArray(goods_attrs[i].attr_value)){
						goods_attrs[i].attr_value = goods_attrs[i].attr_value.join(',');
					}
				}
				this.goodsForm.attrs = JSON.stringify(goods_attrs);
				let specs_attr = [];
				this.specsData.forEach(item=>{
					item.stock = item.stock*1;
					if(!item.price*1>0){
						specs_attr.push(item.spec_1+'/'+item.spec_2);
					}
				})
				if(specs_attr.length > 0){
					loading.close();
					this.$message.error(this.$t('seller.errorMsg.goodsSpecs'));
					this.$refs['goods_spus_item'].$el.scrollIntoView({
							block: 'center',
							behavior: 'smooth'
					})
					return false;
				}
				this.goodsForm.goods_spus = JSON.stringify(this.specsData);
				if(this.shopCateIds.length>0){
					this.goodsForm.store_cate_ids = this.shopCateIds.join(',');
				}
				
				let mobileContent = {
					remark: this.meditorRemark,
					list: this.meditorList
				};
				this.goodsForm.mobile_content = JSON.stringify(mobileContent);
				
				if(this.goodsForm.extract_method!==1){
					this.goodsForm.freight_tpl_id = '';
				}
				
				this.$refs.goodsForm2.validate((valid,object) => {
					if (valid) {
						let flag = 1;
						if(this.specsData.length>0){
							flag = 2;
							this.specsData.forEach(item=>{
								if(parseInt(item.stock) > 0 && parseFloat(this.goodsForm.price)>0 && parseFloat(item.price) == parseFloat(this.goodsForm.price)){
									flag = 1;
								}
							})
						}
						if(flag==2){
							this.$confirm(this.$t('seller.confirm.inconsistent'),this.$t('seller.confirm.title'),{
								confirmButtonText: this.$t('seller.actions.confirm'),
								cancelButtonText: this.$t('seller.actions.cancel'),
								type: 'warning'
							}).then(() =>{
								this.goodsSubmit(this.goodsForm).then(response => {
									loading.close();
									this.$router.push({name:'sellManage'})
								}).catch(()=>{
									loading.close();
								})
							}).catch(() =>{
								loading.close();
							})
						}else {
							this.goodsSubmit(this.goodsForm).then(response => {
								loading.close();
								this.$router.push({name:'sellManage'})
							}).catch(()=>{
								loading.close();
							})
						}
					} else {
						loading.close();
						for (let i in object) {
							this.$refs[i+'_item'].$el.scrollIntoView({
									block: 'center',
									behavior: 'smooth'
							})
							break;
						}
						return false;
					}
				});
			},
			goodsSubmit(params) {
				return new Promise((resolve, reject) => {
					if(this.isEdit) {
						updateGoods(params).then(response => {
							this.$message.success(this.$t('seller.successMsg.submit'))
							resolve(response)
						}).catch(error=>{
							reject(error)
						})
					} else {
						if(params.goods_id) {
							delete params.goods_id
						}
						createGoods(params).then(response => {
							this.$message.success(this.$t('seller.successMsg.publish'))
							resolve(response)
						}).catch(error=>{
							reject(error)
						})
					}
				})
			},
			onClickFeetemplate(){
				window.open('/home/order/batch-consign/logis-tools?type=1')
			}
		},
		watch: {
			'checkColorList': {
				handler: function(val, oldVal){
					this.getAssembleList();
				},
				deep: true,
				immediate: true
			},
			'checkSizeList': {
				handler: function(val, oldVal){
					this.getAssembleList();
				},
				deep: true,
				immediate: true
			},
			'specsData': {
				handler: function(val, oldVal){
					if(oldVal && oldVal.length>0){
						this.getTotalSku();
					}
				},
				deep: true
			},
			'goodsForm.extract_method': {
				handler: function(val, oldVal){
					if(val == 1 && this.feetemplateList.length>0){
						this.goodsForm.freight_tpl_id = this.feetemplateList[0].template_id
					}else {
						this.goodsForm.freight_tpl_id = '';
					}
				},
				deep: true
			},
			'goodsForm.description': {
				handler: function(val, oldVal){
					if(val){
						this.$refs.description_item.clearValidate();
					}
				},
				deep: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-box {
		background-color: #F0F2F5;

		::v-deep .el-scrollbar {
			.el-scrollbar__view {
				padding-bottom: 72px;
			}
		}
	}

	.uu-publish {
		max-width: 1024px;
		width: 100%;
		margin: 0 auto;
		padding: 0 10px 72px;
		box-sizing: border-box;

		.publish-breadcrumb {
			margin: 18px 0;
			height: 64px;
			line-height: 28px;
			padding: 18px;
			font-size: 14px;
			box-sizing: border-box;
			background-color: #FFFFFF;
			box-shadow: 0 -2px 4px 0 rgba(229, 229, 229, .5);

			.title {
				display: inline-block;
				vertical-align: middle;
			}

			::v-deep .el-breadcrumb {
				display: inline-block;
				vertical-align: middle;
			}
		}

		.publish-steps {
			height: 44px;
			background-color: $--seller-primary-white-9;
			margin: 10px 0;
			display: flex;

			.item {
				color: #666666;
				flex: 1;
				height: 44px;
				line-height: 44px;
				text-align: center;
				font-weight: bold;
				margin-left: 22px;
				position: relative;
				cursor: pointer;

				&:first-child {
					margin-left: 0;

					&.active:before {
						display: none;
					}
				}

				&:last-child {
					&.active:after {
						display: none;
					}
				}

				&.active {
					color: #FFFFFF;
					background-color: $--seller-primary-white-2;

					&:before,
					&:after {
						content: "";
						top: 0;
						display: block;
						position: absolute;
					}

					&:before {
						left: -22px;
						border-left: 22px solid transparent;
						border-top: 22px solid $--seller-primary-white-2;
						border-bottom: 22px solid $--seller-primary-white-2;
					}

					&:after {
						right: -22px;
						border-left: 22px solid $--seller-primary-white-2;
						border-top: 22px solid transparent;
						border-bottom: 22px solid transparent;
					}
				}
			}
		}
		::v-deep .el-form {
			.el-form-item__label {
				font-size: 12px;
			}
			.el-form-item__content {
				margin-right: 38px;
				font-size: 12px;
			}
		}
		.publish-section {
			margin: 12px 0;
			padding: 12px 0;
			background-color: #FFFFFF;

			.section-tip {
				color: #949494;
				line-height: 16px;
				margin: 8px 0;

				.red {
					color: #ff6600;
				}

				a {
					color: $--seller-link-color;
					cursor: pointer;

					&:hover {
						color: $--seller-link-hover;
						text-decoration: underline;
					}
				}
			}
			.section-head {
				padding-bottom: 12px;
				border-bottom: 1px solid #EEEEEE;
				margin-bottom: 12px;
				h2 {
					color: #333333;
					font-size: 14px;
					line-height: 1;
					padding: 16px;
					display: inline-block;
				}
			}
		}

		.publish-thumb {
			.item {
				float: left;
				width: 100px;
				height: 100px;
				margin-right: 15px;
				position: relative;
				&:first-child {
					.item-bottom {
						text-align: right;
					}
				}
				&:last-child {
					.item-bottom {
						text-align: left;
					}
				}
				&:hover {
					.item-bottom {
						display: block;
					}
				}
			}
			.item-head {
				top: 0;
				left: 0;
				width: 100%;
				height: 18px;
				line-height: 18px;
				color: #FFFFFF;
				text-align: center;
				position: absolute;
				background-color: rgba(51,51,51,.7);
				em {
					color: #f60;
					margin-right: -6px;
				}
			}
			.item-main {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-flow: column;
				border: 1px dashed #cdcdcd;
				line-height: 1;
				box-sizing: border-box;
				cursor: pointer;
				.el-icon-plus {
					color: $--seller-primary-white-2;
					font-size: 24px;
				}
				p {
					margin-top: 12px;
					color: #666666;
				}
			}
			.item-pic {
				width: 100%;
				height: 100%;
				border: 1px solid #cdcdcd;
				box-sizing: border-box;
				cursor: pointer;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
			.item-bottom {
				width: 100%;
				bottom: 0;
				color: #FFFFFF;
				display: none;
				position: absolute;
				height: 28px;
				background-color: rgba(51,51,51,.7);
				i {
					width: 36px;
					height: 28px;
					line-height: 28px;
					cursor: pointer;
					text-align: center;
					display: inline-block;
					vertical-align: top;
					font-size: 16px;
					&:hover {
						background-color: rgba(51,51,51,.3);
					}
					&.el-icon-delete {
						width: 28px;
					}
				}
			}
			.h150 {
				height: 150px;
			}
			&:after {
				content: "";
				display: block;
				clear: both;
			}
		}
		.publish-cate-path {
			border: 1px solid #e8e8e8;
			background-color: #f7f8fa;
			margin-bottom: 6px;
			line-height: 24px;
			padding: 8px 12px;
			.item-label {
				color: #999999;
				display: inline-block;
				vertical-align: middle;
				margin-right: 10px;
			}
			.item-block {
				color: $--seller-primary;
				display: inline-block;
				vertical-align: middle;
				::v-deep .el-breadcrumb {
					font-size: 12px;
					line-height: 24px;
					.el-breadcrumb__inner {
						color: $--seller-primary;
						cursor: pointer;
						font-weight: normal;
						&:hover {
							color: $--seller-primary-white-2;
						}
					}
				}
			}
		}
		.publish-cate-search {
			::v-deep .el-input {
				width: 500px;
				margin-right: 5px;
			}
		}
		.publish-cate-history {
			.item-label {
				line-height: 24px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 10px;
			}
			.item-block {
				width: 80%;
				display: inline-block;
				vertical-align: middle;
				a {
					color: $--seller-link-color;
					font-weight: normal;
					cursor: pointer;
					&:hover {
						color: $--seller-link-hover;
					}
				}
			}
		}
		.publish-cate-lists {
			.item {
				width: 25%;
				display: inline-block;
				border: 1px solid #EEEEEE;
				border-left: 0;
				&:first-child {
					border-left: 1px solid #EEEEEE;
				}
				.item-head {
					margin: 20px 5%;
				}
				.item-main {
					height: 260px;
					overflow-y: auto;
				}
				.item-group {
					margin-right: 2px;
					li {
						height: 28px;
						line-height: 28px;
						padding: 0 10px;
						border-left: 2px solid transparent;
						display: flex;
						overflow: hidden;
						cursor: pointer;
						color: $--live-gray-black-16;
						p {
							flex: 1;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						i {
							padding-left: 4px;
							line-height: 28px;
							font-size: 14px;
						}
						&:hover {
							background-color: #f4f4f4;
						}
						&.active {
							border-color: $--seller-primary-white-2;
							background-color: #f4f4f4;
						}
					}
				}
			}
		}
		.publish-cate-attr {
			padding: 24px 0 18px;
			background-color: #fcfcfc;
			border: 1px solid #eee;
			.item {
				width: 50%;
				float: left;
				.item-label {
					float: left;
					width: 120px;
					padding-right: 12px;
					box-sizing: border-box;
					text-align: right;
				}
				.item-block {
					margin-left: 120px;
					max-width: 240px;
					.el-date-editor {
						width: 100%;
					}
				}
				.item-msg {
					min-height: 18px;
					line-height: 18px;
					margin-bottom: 6px;
					color: #949494;
				}
			}
			::v-deep .el-select {
				width: 100%;
			}
			&:after {
				content: "";
				display: block;
				clear: both;
			}
		}
		.publish-color {
			li {
				margin-top: 12px;
			}
		}
		.publish-size {
			margin-top: 12px;
			padding-bottom: 12px;
			background-color: #fcfcfc;
			border: 1px solid #EEEEEE;
			.item {
				float: left;
				min-width: 110px;
				margin-top: 12px;
				padding-left: 8px;
				margin-right: 0;
				.item-icon {
					color: #666666;
					font-size: 16px;
					margin-left: 8px;
					cursor: pointer;
					vertical-align: middle;
					&:hover {
						color: $--seller-primary;
					}
				}
			}
			::v-deep .el-checkbox-group {
				line-height: 20px;
				overflow: hidden;
				.el-checkbox {
					vertical-align: middle;
				}
			}
		}
		::v-deep .el-table {
			th.el-table__cell {
				background-color: #f5f7fa;
				.cell {
					position: relative;
					em {
						color: #f60;
						top: 50%;
						left: 1px;
						font-size: 12px;
						line-height: 1;
						margin-top: -6px;
						position: absolute;
					}
				}
			}
			.specs-img {
				width: 18px;
				height: 18px;
				display: inline-block;
				vertical-align: middle;
				box-shadow: 0 0 0 1px #e5e5e5 inset;
			}
			.specs-link {
				color: $--seller-link-color;
				margin-left: 4px;
				&:hover {
					color: $--seller-link-hover;
				}
			}
		}
		.publish-logis {
			padding: 10px;
			margin-bottom: 8px;
			border: 1px solid #EEEEEE;
			background-color: #fcfcfc;
			.item-label {
				width: 8%;
				float: left;
				em {
					color: red;
				}
			}
			.item-block {
				margin-left: 8%;
			}
			.item-desc {
				line-height: 28px;
				font-size: 12px;
				color: #767676;
				border-bottom: 1px dashed #e6e7eb;
			}
		}
		.publish-video {
			.upload-video {
				width: 100px;
				height: 100px;
				flex-flow: column;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				color: #CCCCCC;
				border-radius: 6px;
				border: 1px dashed #d7dbe0;
				i {
					color: $--seller-primary-white-2;
					font-size: 32px;
				}
				p {
					font-size: 14px;
					line-height: 20px;
					text-align: center;
				}
			}
		}
		.publish-meditor {
			background-color: #7d7d7d;
			.meditor-head {
				height: 36px;
				line-height: 36px;
				padding: 0 10px;
				background-color: #F2F2F2;
				border: 1px solid #D1D1D1;
				.head-bar {
					float: left;
				}
			}
			.meditor-content {
				width: 322px;
				height: 465px;
				margin: 1px auto 0;
			}
			.meditor-body {
				border: 1px solid #DEDEDE;
				width: 100%;
				height: 400px;
				background-color: #FFFFFF;
				overflow-y: scroll;
				box-sizing: border-box;
				.item {
					padding: 10px 0;
					width: 290px;
					position: relative;
					margin: 0 auto;
					box-sizing: border-box;
					.item-main {
						font-size: 12px;
						line-height: 150%;
						margin: 0;
						min-height: 30px;
						i {
							font-size: 20px;
							display: inline-block;
							vertical-align: top;
							margin-right: 5px;
						}
						::v-deep img {
							max-width: 100%;
							height: auto;
						}
						&.summary {
							margin: 0 15px;
							border-bottom: 1px solid #dedede;
						}
					}
					.item-tools {
						top: 0;
						display: none;
						width: 290px;
						height: 100%;
						position: absolute;
						.tools-bg {
							width: 100%;
							height: 100%;
							background-color: rgba(0,0,0,.5);
						}
						.tools-btns {
							top: 15px;
							right: 15px;
							position: absolute;
							.tools-btn {
								float: left;
								background-color: #F7F8FA;
								border: 1px solid #c4c6cf;
								height: 24px;
								display: inline-block;
								cursor: pointer;
								line-height: 24px;
								padding: 0 12px;
								&:first-child {
									border-top-left-radius: 3px;
									border-bottom-left-radius: 3px;
								}
								&:last-child {
									border-top-right-radius: 3px;
									border-bottom-right-radius: 3px;
								}
							}
						}
					}
					.item-input {
						padding: 10px;
						display: block;
						box-sizing: border-box;
						background-color: #F2F2F2;
						.mt15 {
							margin-top: 15px;
						}
					}
					&:hover {
						.item-tools {
							display: block;
						}
					}
				}
			}
			.meditor-footer {
				height: 60px;
				width: 100%;
				background-color: #ececec;
				overflow: hidden;
				.footnav {
					display: flex;
					.item {
						flex: 1;
						color: #878787;
						cursor: pointer;
						text-align: center;
						line-height: normal;
						.item-icon {
							font-size: 18px;
							line-height: 32px;
						}
						.item-text {
							font-size: 12px;
							line-height: 28px;
						}
					}
					&.navrow {
						display: none;
					}
				}
				&:hover {
					.navrow {
						display: flex;
					}
					.navadd {
						display: none;
					}
				}
			}
		}
		.publish-footer {
			left: 0;
			bottom: 0;
			width: 100%;
			z-index: 99;
			// max-width: 1098px;
			position: absolute;
			text-align: center;
			border-top: 2px solid $--seller-primary-white-2;
			padding: 15px 0;
			background-color: #FFFFFF;
			// transform: translateX(-50%);
		}
	}
	.uu-form-inline {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		&.w200 {
			width: 200px;
		}
		&.mr0 {
			margin-right: 0;
		}
		&.mr30 {
			margin-right: 30px;
		}
		::v-deep .el-radio {
			margin-right: 0;
		}
	}
	.popper-cate-list {
		max-height: 240px;
		overflow-y: auto;
		li {
			cursor: pointer;
			padding: 7px 0;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		::v-deep .el-breadcrumb {
			.el-breadcrumb__inner {
				cursor: pointer;
			}
		}
	}
	.uu-icon-question {
		color: #999;
	}
	::v-deep .el-radio {
		.el-radio__label {
			color: inherit;
		}
	}
	::v-deep .el-checkbox {
		.el-checkbox__label {
			color: inherit;
		}
	}
</style>
